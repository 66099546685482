import emailSettingsEditRoute from "./emailSettingsEditRoute"
import EmailValidateButton from "./EmailValidateButton"

export { emailSettingsEditRoute, EmailValidateButton }

const resource = {
  name: "emailSettings",
}

export default resource
