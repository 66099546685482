/* eslint-disable no-console */
export {}

if (process.env.NODE_ENV !== "production") {
  ;(() => {
    const oldLogError = console.error
    console.error = function error(...args: any[]) {
      if (typeof args[1] === "string") {
        // react-helmet SideEffect.
        if (args[1].match(/SideEffect/)) {
          return
        }
        // findDOMNode is deprecated
        if (args[1] === "findDOMNode" && args[3] === "Transition") {
          return
        }
      }
      oldLogError.apply(console, args)
    }
    // const oldLogWarn = console.warn
    // console.warn = function warn(...args: any[]) {
    //   if (typeof args[0] !== "string" || args[0].indexOf("history") === -1) {
    //     oldLogWarn.apply(console, args)
    //   }
    // }
  })()
}
