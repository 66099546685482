import cacheSettingsEditRoute from "./cacheSettingsEditRoute"
import CacheValidateButton from "./CacheValidateButton"

export { cacheSettingsEditRoute, CacheValidateButton }

const resource = {
  name: "cacheSettings",
}

export default resource
