import ldapSettingsEditRoute from "./ldapSettingsEditRoute"
import LdapValidateButton from "./LdapValidateButton"

export { ldapSettingsEditRoute, LdapValidateButton }

const resource = {
  name: "ldapSettings",
}

export default resource
